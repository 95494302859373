import React from "react"
import Helmet from 'react-helmet';

export default function HelmetHead() {
  const defaultPageTitle = 'Lava Llama';
  const metaDescription = 'Lava Llama is a free, 8-bit runner game for iOS and Android. Guide your llama to safety!';
  const metaKeywords = 'lava llama, indie games, mobile games, platformer, iphone, ipad, ios, android, games, wednesday night games';
  const metaAuthor = 'Wednesday Night Games, LLC';
  const metaImageUrl = 'https://www.lavallama.com/share-images/lava-llama-share-icon.png';

  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>{defaultPageTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="author" content={metaAuthor} />

      {/* Favicon and icon */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ff2902" />
      <meta name="apple-mobile-web-app-title" content="LavaLlama.com" />
      <meta name="application-name" content="LavaLlama.com" />
      <meta name="theme-color" content="#ffffff" />

      {/* OpenGraph Metadata for Facebook */}
      <meta property="og:url" content="https://www.lavallama.com/" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content={defaultPageTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImageUrl} />
    </Helmet>
  );
}
